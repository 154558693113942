<template>
  <a-spin :spinning="requestLoading">
    <div class="tab">
      <a-tabs
        v-model:activeKey="activeKey"
        tabPosition="top"
        :tabBarGutter="48"
      >
        <a-tab-pane key="1" tab="OVERVIEW">
          <Overview :designer="designer" />
        </a-tab-pane>
        <a-tab-pane key="2" tab="EDIT ACCOUNT" force-render>
          <EditAccount :designer="designer" />
        </a-tab-pane>
        <a-tab-pane key="3" tab="DESIGNER SETTINGS" force-render>
          <DesignerSettings :designer="designer" />
        </a-tab-pane>
        <a-tab-pane key="4" tab="BILLING">
          <Billing />
        </a-tab-pane>
      </a-tabs>
    </div>
  </a-spin>
</template>
<script>
import { ref, computed, defineAsyncComponent } from 'vue'
import { useStore } from 'vuex'
const Overview = defineAsyncComponent(() =>
  import('@/views/Settings/Components/Overview')
)
const EditAccount = defineAsyncComponent(() =>
  import('@/views/Settings/Components/EditAccount')
)
const Billing = defineAsyncComponent(() =>
  import('@/views/Settings/Components/Billing')
)
const DesignerSettings = defineAsyncComponent(() =>
  import('@/views/Settings/Components/DesignerSettings')
)
import useLoadingState from '@/use/loading-state'

export default {
  name: 'SettingsTab',
  components: {
    Overview,
    EditAccount,
    Billing,
    DesignerSettings
  },
  setup() {
    const store = useStore()
    const { requestLoading } = useLoadingState()
    let currentUser = computed(() => store.getters['auth/GetCurrentUser'])
    let designer = computed(() => store.getters['designer/GetDesigner'])
    store.dispatch('designer/FetchDesigner', {
      designerId: currentUser.value._id
    })
    return {
      activeKey: ref('1'),
      designer,
      requestLoading
    }
  }
}
</script>
<style scoped></style>
